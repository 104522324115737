import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { init as initSentry, } from "@sentry/angular";
import env from '@env';


if (env.production) {
  enableProdMode();
  initSentry({
    dsn: "https://87fbb82e1d144d0ca4bc1ff376954b66@o756980.ingest.sentry.io/5792265" ,
    integrations: [],
    tracesSampleRate: 1.0,
    environment: env.configuration
  });
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    // do not use ngZoneRunCoalescing as it prevent the app to work
    // .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
