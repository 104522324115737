import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import env from '@env';

const materialIcons = {
  account_balance_wallet: 12,
  account_circle: 20,
  add: 21,
  add_business: 17,
  add_circle_outline: 16,
  add_shopping_cart: 15,
  arrow_back: 17,
  arrow_forward: 13,
  assessment: 12,
  av_timer: 12,
  brightness_3: 16,
  brightness_5: 12,
  cached: 13,
  call: 17,
  cancel: 16,
  check_box_outline_blank: 16,
  check_circle_outline: 12,
  checklist_rtl: 3,
  chevron_left: 16,
  chevron_right: 16,
  close: 19,
  cloud_download: 12,
  cloud_upload: 12,
  contacts: 11,
  content_copy: 17,
  credit_card: 12,
  credit_card_off: 4,
  crop: 12,
  dashboard: 13,
  delete: 17,
  discount: 1,
  done: 19,
  download: 10,
  edit: 12,
  event: 21,
  event_repeat: 1,
  exit_to_app: 13,
  expand_more: 14,
  favorite: 17,
  filter_list: 13,
  format_align_center: 12,
  format_align_justify: 12,
  format_align_left: 13,
  format_align_right: 13,
  format_bold: 12,
  format_italic: 12,
  format_list_bulleted: 13,
  format_list_numbered: 12,
  format_strikethrough: 12,
  format_underlined: 13,
  help_outline: 12,
  highlight_off: 17,
  hourglass_bottom: 11,
  info: 20,
  keyboard_arrow_left: 17,
  keyboard_arrow_right: 17,
  link: 21,
  list: 17,
  local_mall: 12,
  local_offer: 20,
  local_phone: 17,
  lock: 18,
  mail: 16,
  manage_accounts: 10,
  map: 16,
  pause: 16,
  pause_circle_outline: 19,
  payment: 12,
  payments: 13,
  percent: 1,
  perm_media: 15,
  perm_phone_msg: 12,
  person: 17,
  person_pin: 13,
  play_circle_outline: 19,
  print: 16,
  receipt_long: 12,
  remove: 16,
  replay: 13,
  rule: 8,
  save: 12,
  savings: 5,
  schedule: 17,
  search: 17,
  send: 20,
  share: 18,
  shopping_bag: 9,
  shopping_basket: 12,
  shopping_cart: 18,
  store: 11,
  sync: 17,
  translate: 16,
  update: 13,
  upload: 10,
  verified_user: 18,
  visibility: 16,
  visibility_off: 12
};


// Unfortunately needs HttpClientModule
@Injectable({ providedIn: 'root' })
export class IconService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private registry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {}

  registerMaterial() {
    for (const [name, version] of Object.entries(materialIcons)) {
      const path = isPlatformServer(this.platformId)
        ? `https://fonts.gstatic.com/s/i/materialicons/${name}/v${version}/24px.svg`
        : `/assets/mat-icons/${name}.svg`;
      const url = this.sanitizer.bypassSecurityTrustResourceUrl(path);
      this.registry.addSvgIcon(name, url);
    }
  }

  register(names: string | string[]) {
    const run = (name: string) => {
      const path = isPlatformServer(this.platformId)
        ? `${env.baseUrl}/assets/mat-icons/${name}.svg`
        : `/assets/mat-icons/${name}.svg`;
      const url = this.sanitizer.bypassSecurityTrustResourceUrl(path);
      this.registry.addSvgIcon(name, url);
    };
    Array.isArray(names) ? names.forEach(run) : run(names);
  }
}
