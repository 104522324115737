import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Storage as CapStorage } from '@capacitor/storage';

@Injectable({ providedIn: 'root' })
export class Storage {
  constructor(@Inject(PLATFORM_ID) private plateformId: Object) {}

  set(key: string, value: any) {
    if (!isPlatformBrowser(this.plateformId)) return;
    return CapStorage.set({ key, value: JSON.stringify(value) });
  }

  async get<T>(key: string): Promise<T | null> {
    if (!isPlatformBrowser(this.plateformId)) return Promise.resolve(null);
    const { value } = await CapStorage.get({ key });
    return value ? JSON.parse(value) : null;
  }
}
