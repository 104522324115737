import { ChangeDetectionStrategy, Component, Output, EventEmitter } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import env from '@env';

@Component({
  selector: 'dev-mode',
  templateUrl: './dev-mode.component.html',
  styleUrls: ['./dev-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: TRANSLOCO_SCOPE,
    useValue: 'home'
  }]
})
export class DevModeComponent {
  @Output() close = new EventEmitter();
}
