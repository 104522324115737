import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import type { SoftwareApplication, Thing, WebApplication, GroceryStore, WithContext } from 'schema-dts';

export const dayNames = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday"
}

const applicationCategories = [
  'GameApplication',
  'SocialNetworkingApplication',
  'TravelApplication',
  'ShoppingApplication',
  'SportsApplication',
  'LifestyleApplication',
  'BusinessApplication',
  'DesignApplication',
  'DeveloperApplication',
  'DriverApplication',
  'EducationalApplication',
  'HealthApplication',
  'FinanceApplication',
  'SecurityApplication',
  'BrowserApplication',
  'CommunicationApplication',
  'DesktopEnhancementApplication',
  'EntertainmentApplication',
  'MultimediaApplication',
  'HomeApplication',
  'UtilitiesApplication',
  'ReferenceApplication',
] as const;

type ApplicationCategory = (typeof applicationCategories)[number];

interface WebApp extends Omit<WebApplication, '@type'> {
  applicationCategory: ApplicationCategory
}

export interface LocalMarket extends Omit<GroceryStore, "@type">{}

@Injectable({ providedIn: 'root' })
export class JsonLDService {

  constructor(@Inject(DOCUMENT) private document: Document) {}

  app(app: WebApp) {
    const schema: WithContext<SoftwareApplication> = {
      ...app,
      "@context": "https://schema.org",
      "@type": "WebApplication",
    };
    this.createScript(schema, 'app');
  }

  market(market: LocalMarket) {
    const schema: WithContext<GroceryStore> = {
      ...market,
      "@context": "https://schema.org",
      "@type": "GroceryStore",
    }
    this.createScript(schema, 'market');
  }

  private createScript<T extends Thing>(schema: WithContext<T>, id?: string) {
    const el = id && this.document.getElementById(`jsonld_${id}`);
    if (el) {
      el.textContent = JSON.stringify(schema);
    } else {
      const script = this.document.createElement('script');
      script.type = 'application/ld+json';
      script.id = `jsonld_${id}`;
      script.textContent = JSON.stringify(schema);
      this.document.head.appendChild(script);
    }
  }
}