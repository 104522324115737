export default (firebaseOptions = {}) => ({
  configuration: 'production',
  baseUrl: 'https://kampoy.com',
  production: true,
  firebaseConfig: {
    options: {
      apiKey: 'AIzaSyA6Q-nPUJlbnpzaLI4cvVJixyNVEvh79bY',
      authDomain: 'prod-kampoy.firebaseapp.com',
      projectId: 'prod-kampoy',
      storageBucket: 'prod-kampoy.appspot.com',
      messagingSenderId: '447032835487',
      appId: '1:447032835487:web:c3375b02f759e96fc711aa',
      measurementId: 'G-XB5V7FN8X4',
    },
    ...firebaseOptions,
    vapidKey: 'BEOwiExcIkGDbku1aLVMgYx1TIrU87lPH-RZ0w9D-70xSIBwIcvJhOsVU3KbQ9oXKN82QPI-hjRx0nHYr7vTIJY'
  },
  googleMap: 'AIzaSyCHdKoojC80__0Kee7xFEPKvNQOvDEJz90',
  contact: 'contact@kampoy.com',
  stripe: {
    publicKey: 'pk_live_51LuYlCHAv8UeqORhVTEgFuvPttoIRpVTVV5tXdUhprKTRH1CwGIVKVQ02bWt4reKPG1OwpW8FUhVw0l6glfOpfZm00qjqHZGGB',
    livemode: true
  },
  useEmulators: false,
  sendgrid: {
    email: 'contact@kampoy.com',
    sandbox: false,
    templateIds: {
      newSeller: 'd-6d1d6fa2b132498384e86450816168d7',
      receipt: 'd-a9d11755ab764dcba8ef5d90cf335509',
      invoice: 'd-99ef9b25c7b54fc7904173c62298a33a',
      monthlyKampoyExpense: 'd-e7c15789bc1549d8bead19fec99a9b07',
      deliveryDeleted: 'd-ec56976280cf453ebb2f40f4402d0e94',
      feeUpdate: 'd-927931956ba8477686e3ffddaca73b89',
      invoiceUpdate: 'd-c0d1fc7ea3b84a00a746f2156b72d7a7',
      invoiceDelete: 'd-46fa2f17700e4c099919c3ffa32f34ce',
    },
  },
  twilio: {
    from: '+33757901202',
    sandbox: false,
  },
});
