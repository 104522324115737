<ng-container *transloco="let t; read: 'home.devmode'">
  <div class="text">
    <h2>{{ t('title') }}</h2>
    <p>{{ t('description') }}</p>
  </div>
  <div class="actions">
    <a mat-flat-button color="primary" href="https://kampoy.com">{{ t('official') }}</a>
    <button mat-button (click)="close.emit()">{{ t('stay') }}</button>
  </div>
</ng-container>
