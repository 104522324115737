import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppComponent } from './app.component';
import { TranslocoRootModule } from './app.transloco.module';

// Import environement instead of @env to run firebase auth
import env from '@env';
import { DevModeModule } from '@ui/dev-mode';
import { createErrorHandler } from '@sentry/angular';
import { FIREBASE_CONFIG, FIRESTORE_SETTINGS, REGION_OR_DOMAIN } from 'ngfire/tokens';
import { HttpClientModule } from '@angular/common/http';
import { RIVE_VERSION } from 'ng-rive';

const SENTRY_ERROR_HANDLER = env.production
  ? [
      {
        provide: ErrorHandler,
        useValue: createErrorHandler({ showDialog: false }),
      },
    ]
  : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    DevModeModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    TranslocoRootModule,
    QuicklinkModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: env.production,
      registrationStrategy: 'registerWhenStable',
    }),
    RouterModule.forRoot([
      {
        path: '',
        loadChildren: () => import('./buyer/shell.module').then((m) => m.BuyerShellModule),
      },
      {
        path: 's',
        loadChildren: () => import('./seller/shell.module').then((m) => m.SellerShellModule),
      },
      {
        path: 'print',
        loadChildren: () => import('./print/print.module').then(m => m.PrintModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin').then(m => m.routes)
      },
      {
        path: '**',
        data: { animation: 'dialog' },
        loadChildren: () =>
          import('./home/not-found/not-found.module').then((m) => m.NotFoundModule),
      },
    ],{
        initialNavigation: 'enabledBlocking',
        anchorScrolling: 'disabled',
        paramsInheritanceStrategy: 'always',
        // preloadingStrategy: QuicklinkStrategy
      }
    ),
  ],
  providers: [
    { provide: REGION_OR_DOMAIN, useValue: 'europe-west1' },
    {
      provide: FIREBASE_CONFIG,
      useValue: env.firebaseConfig,
    },
    { provide: FIRESTORE_SETTINGS, useValue: { experimentalAutoDetectLongPolling: true } },
    { provide: RIVE_VERSION, useValue: '1.1.4' },
    ...SENTRY_ERROR_HANDLER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
