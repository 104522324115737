import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevModeComponent } from './dev-mode.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [DevModeComponent],
  exports: [DevModeComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslocoModule,
  ]
})
export class DevModeModule { }
