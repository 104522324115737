import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Storage } from './storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  isDark$ = new BehaviorSubject(false);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private storage: Storage,
    private media: MediaMatcher
  ) {}

  get isDark() {
    return this.isDark$.getValue();
  }
  set isDark(isDark: boolean) {
    this.isDark$.next(isDark);
  }

  async init() {
    // Init with media query
    const isDarkMedia = this.media.matchMedia('(prefers-color-scheme: dark)');
    isDarkMedia.onchange = ({ matches }) => this.setTheme(matches);
    this.setTheme(isDarkMedia.matches);

    // Init with
    const isDark = await this.storage.get('isDark');
    if (typeof isDark === 'boolean') this.toggle(isDark);
  }

  private setTheme(isDark: boolean) {
    this.isDark = isDark;
    this.document.body.classList.toggle('light-theme', !isDark);
    this.document.body.classList.toggle('dark-theme', isDark);
  }

  /** Using toggle will store the preference of theme */
  toggle(isDark?: boolean): void {
    if (typeof isDark !== 'boolean') return this.toggle(!this.isDark);
    this.storage.set('isDark', isDark);
    this.setTheme(isDark);
  }
}
