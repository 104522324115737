import { DOCUMENT, isPlatformServer, ɵgetDOM as getDOM } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { forkJoin, Subscription } from 'rxjs';

function getMedia(theme?: 'light' | 'dark', size?: 'mobile' | 'desktop') {
  const queries: string[] = [];
  if (theme) queries.push(`(prefers-color-scheme: ${theme})`);
  if (size === 'mobile') queries.push('(max-width: 599.9px)');
  if (size === 'desktop') queries.push('(min-width: 599.9px)');
  if (!queries.length) return;
  return queries.join(' and ');
}

@Injectable({ providedIn: 'root' })
export class Preloader {
  lastPreload = this.doc.head.querySelector('#last-preload');
  dom = getDOM();
  constructor(
    @Inject(PLATFORM_ID) private plateformId: Object,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  // TODO: looks like the browser doesn't take the media attribute into account for prefetch
  prefetch(url: string, theme?: 'light' | 'dark', size?: 'mobile' | 'desktop') {
    this.addImageLink('prefetch', url, theme, size);
  }

  preload(url: string, theme?: 'light' | 'dark', size?: 'mobile' | 'desktop') {
    if (isPlatformServer(this.plateformId)) {
      this.addImageLink('preload', url, theme, size);
    }
  }

  private addImageLink(
    mode: 'preload' | 'prefetch',
    url: string,
    theme?: 'light' | 'dark',
    size?: 'mobile' | 'desktop'
  ) {
    const href = `/assets/img/${url}`;
    const exists = !!this.doc.head.querySelector(`link[rel="${mode}"][href="${href}"]`);
    if (exists) return;
    const link = this.dom.createElement('link') as HTMLLinkElement;
    link.setAttribute('rel', mode);
    link.setAttribute('as', 'image');
    link.setAttribute('href', href);
    const media = getMedia(theme, size);
    if (media) link.setAttribute('media', media);
    if (this.lastPreload) {
      this.lastPreload.after(link);
    } else {
      this.doc.head.appendChild(link);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class TranslocoPreloader implements OnDestroy {
  private idleCallbackId: any;
  private sub?: Subscription;

  constructor(private service: TranslocoService) {}

  preload(scopes: string[] = []) {
    if (typeof window === 'undefined' || this.idleCallbackId) return;
    if ('requestIdleCallback' in window) {
      this.idleCallbackId = (window as any).requestIdleCallback(() => {
        const preloads = scopes.map((scope) => {
          const lang = this.service._completeScopeWithLang(scope);
          return this.service.load(lang);
        });
        this.sub = forkJoin(preloads).subscribe();
      });
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    if (typeof window === 'undefined' || !this.idleCallbackId) return;
    (window as any).cancelIdleCallback(this.idleCallbackId);
  }
}
